import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"

const Results = ({ classes, res }) => {
  return <div>{JSON.stringify(res)}</div>
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(Results)
