import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ showAddStaff, setShowAddStaff, className, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            className={classes.link}
            color="inherit"
            to="/manager/"
          >
            Dashboard
          </Link>

          <Typography variant="body1" color="textPrimary">
            Manage Staff
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Manage Staff
          </Typography>
        </Box>
        {/* <Box mt={2}>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <UploadIcon />
            </SvgIcon>
            Import
          </Button>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <DownloadIcon />
            </SvgIcon>
            Export
          </Button>
        </Box> */}
      </Grid>
      <Grid item>
        {!showAddStaff ? (
          <Button
            onClick={() => setShowAddStaff(true)}
            color="secondary"
            variant="contained"
            className={classes.action}
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <PlusCircleIcon />
            </SvgIcon>
            Add Staff
          </Button>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
