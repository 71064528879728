import React, { useState, useContext } from "react"
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import TasksContext from "../../../../../../context/TasksContext"
import { Droppable, Draggable } from "react-beautiful-dnd"
import clsx from "clsx"

const AssignedTaskCard = ({ classes, tsk, index }) => {
  const { state, dispatch } = useContext(TasksContext)

  return (
    <div key={tsk.id}>
      <Draggable draggableId={`task-${tsk.id}`} index={index}>
        {(provided, snapshot) => (
          <Card
            className={
              snapshot.isDragging
                ? clsx(classes.taskCard, classes.draggingTask)
                : classes.taskCard
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <CardHeader title={tsk.title} />
            <CardContent>
              {/* provided:
        {JSON.stringify(provided)} */}
              <Typography>provided: {JSON.stringify(provided)}</Typography>
            </CardContent>
          </Card>
        )}
      </Draggable>
    </div>
  )
}

const styles = theme => ({
  root: {},
  taskCard: {
    marginTop: theme.spacing(1),
    borderRadius: "15px",
    boxShadow: "0 2px 4px 2px rgba(0,0,0, .15)",
  },
  draggingTask: {
    backgroundColor: "#ADD8E6",
  },
})
export default withStyles(styles)(AssignedTaskCard)
