import React, { useState, useContext } from "react"
import {
  Box,
  Button,
  Grid,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  // Pagination,
  Paper,
  Select,
  Typography,
  TextField,
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"

import withStyles from "@material-ui/core/styles/withStyles"
import TasksContext from "../../../../../context/TasksContext"
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"
const FilterTasks = ({ classes }) => {
  const { state, dispatch } = useContext(TasksContext)
  const search = state.search
  const pageSize = state.pageSize
  const [searchBar, setSearchBar] = useState("")
  const handleSubmit = event => {
    console.log("submit")
  }
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }
  const handleClearFilters = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
    dispatch({ type: "SET_PAGE", payload: 0 })
    dispatch({ type: "SET_PAGE_SIZE", payload: 10 })
    // setUserFilter(false)
    // setPreregFilter(false)
    // setCurrentTab("all")
  }
  const handleChangePageSize = () => {
    console.log("new page size")
  }
  return (
    <div>
      <Typography>Filter tasks</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={4}>
          <Pagination count={10} variant="outlined" shape="rounded" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pageSize}
              label="Age"
              onChange={handleChangePageSize}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pageSize}
              label="Age"
              onChange={handleChangePageSize}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper
            component="form"
            onSubmit={event => {
              event.preventDefault()
              handleSearch(event)
            }}
            className={classes.searchField}
          >
            <InputBase
              fullWidth
              className={classes.input}
              placeholder="Search Tasks"
              inputProps={{ "aria-label": "search tasks" }}
              onChange={event => setSearchBar(event.target.value)}
              value={searchBar}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="clear"
              onClick={handleClearSearchResults}
            >
              <CancelIcon />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {},
  field: {
    margin: 0,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
})
export default withStyles(styles)(FilterTasks)
