import React, { useState, useContext } from "react"
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { Eye as ViewIcon } from "react-feather"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import TasksContext from "../../../../../context/TasksContext"
import getInitials from "../../../../../utils/getInitials"
const TaskCard = ({ classes, task, onSelectTask, provided }) => {
  const { state, dispatch } = useContext(TasksContext)
  const selTaskId = state.selectedTask ? state.selectedTask.id : ""
  const title =
    task.title.length > 23 ? task.title.slice(0, 23) + "..." : task.title
  const assignedToName = task.assignedTo
    ? task.assignedTo.person.firstName + " " + task.assignedTo.person.lastName
    : "Not Assigned"
  return (
    <Card
      className={
        selTaskId == task.id ? classes.selectedCard : classes.styledCard
      }
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <CardHeader
        avatar={
          <Avatar
            sizes="small"
            className={classes.avatar}
            style={
              // taskId == task.id ? { color: "#ffffff" } : { color: "#000000" }
              selTaskId == task.id ? { color: "#ffffff" } : { color: "#000000" }
            }
            aria-label="initial"
          >
            {getInitials(assignedToName)}
          </Avatar>
        }
        action={
          selTaskId == task.id ? (
            <>
              <IconButton aria-label="settings" onClick={onSelectTask}>
                <MoreVertIcon />
              </IconButton>
            </>
          ) : (
            <IconButton
              aria-label="settings"
              onClick={() =>
                dispatch({ type: "SET_SELECTED_TASK", payload: task })
              }
            >
              <SvgIcon>
                <ViewIcon />
              </SvgIcon>
            </IconButton>
          )
        }
        title={title}
        subheader={
          task.assignedTo
            ? `Assigned To: ${task.assignedTo?.person.firstName} ${task.assignedTo?.person.lastName}`
            : "Unassigned"
        }
      />
      {/* <CardContent>
            <Typography variant="body1">
              Position: {task.position}, Priority: {task.priority}
            </Typography>
          </CardContent> */}
    </Card>
  )
}

const styles = theme => ({
  root: {},
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: "16px",
  },
  selectedCard: {
    background: theme.palette.background.default,
    borderRadius: 3,
    border: 0,

    height: 60,
    padding: "0 10px",

    marginBottom: 8,
  },
  styledCard: {
    background: theme.palette.background.paper,
    borderRadius: 3,
    border: 0,

    height: 60,
    padding: "0 10px",
    marginBottom: 8,
  },
  label: {
    textTransform: "capitalize",
  },
})
export default withStyles(styles)(TaskCard)
