import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { Doughnut } from "react-chartjs-2"
import { useTheme } from "@material-ui/core"
import PropTypes from "prop-types"
const SalesBySbuDonut = ({ classes, ...rest }) => {
  const theme = useTheme()
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 15, 4],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div
      position="relative"
      style={{
        width: "20rem",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Doughnut data={data} />
    </div>
  )
}

const styles = theme => ({
  root: {},
})
SalesBySbuDonut.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
}
export default withStyles(styles)(SalesBySbuDonut)
