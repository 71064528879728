import React, { useState, useEffect, useContext } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import moment from "moment"
import { Alert } from "@material-ui/lab"
import withStyles from "@material-ui/core/styles/withStyles"
import clsx from "clsx"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import UndoIcon from "@material-ui/icons/Undo"
import CloseIcon from "@material-ui/icons/Close"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { truncate } from "lodash"
import { Eye as ViewIcon } from "react-feather"
import TaskCard from "./TaskCard"
import TasksContext from "../../../../../context/TasksContext"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"

const taskDepartments = [
  { name: "general", description: "General Task" },
  { name: "accounting", description: "Accounting Task" },
  { name: "service", description: "Customer Service Task" },
  { name: "item", description: "Item Task" },
  { name: "management", description: "Management Task" },
  { name: "marketing", description: "Marketing Task" },
  { name: "order", description: "Order Task" },
  { name: "project", description: "Project Task" },
  { name: "quote", description: "Quote Task" },
  { name: "research", description: "Research Task" },
  { name: "shipping", description: "Shipping Task" },
  { name: "supplier", description: "Supplier Task" },
]

const NewTask = ({ classes, ...rest }) => {
  const { state, dispatch } = useContext(TasksContext)
  const tasks = state.tasks
  const selTask = state.selectedTask
  const [title, setTitle] = useState("")
  const [assignedToId, setAssignedToId] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [dueDate, setDueDate] = useState(null)
  const [completed, setCompleted] = useState(false)
  const [completedDate, setCompletedDate] = useState(null)
  const [note, setNote] = useState("")
  const [priority, setPriority] = useState(1)
  const [minutesEstimate, setMinutesEstimate] = useState(null)
  const [minutesReported, setMinutesReported] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [department, setDepartment] = useState("general")
  const [projectId, setProjectId] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [itemId, setItemId] = useState(null)
  const [quoteId, setQuoteId] = useState(null)
  const [supplierUid, setSupplierUid] = useState("")
  const [position, setPosition] = useState(1)
  const tz = Intl.DateTimeFormat({ timeZone: "UTC" }).resolvedOptions().timeZone
  var date = new Date()
  const offset = date.getTimezoneOffset()
  const { loading, error, data } = useQuery(TASK_FORM_QUERY, {
    fetchPolicy: "cache-and-network",
  })
  const [createTask] = useMutation(CREATE_TASK_MUTATION, {
    variables: {
      title: title,
      categoryId: categoryId,
      projectId: projectId,
      orderId: orderId,
      itemId: itemId,
      quoteId: quoteId,
      supplierUid: supplierUid,
      dueDate: dueDate,
      completedDate: completedDate,
      completed: completed,
      assignedToId: assignedToId,
      note: note,
      priority: priority,
      position: position,
      department: department,
      minutesEstimate: minutesEstimate,
      minutesReported: minutesReported,
      timeZone: tz,
    },
    onCompleted: data => {
      console.log("updated")
      console.log(data)
      const newTask = data.createTask.task
      const prevTasks = state.tasks
      const updatedTasks = [...prevTasks, newTask]
      dispatch({ type: "SET_TASKS", payload: updatedTasks })
    },
  })
  const handleSubmit = () => {
    console.log("submit")
    console.log(offset)
    // setSubmitting(true)
    createTask()
    const myVariables = {
      title: title,
      categoryId: categoryId,
      projectId: projectId,
      orderId: orderId,
      itemId: itemId,
      quoteId: quoteId,
      supplierUid: supplierUid,
      dueDate: dueDate,
      completedDate: completedDate,
      completed: completed,
      assignedToId: assignedToId,
      note: note,
      priority: priority,
      position: position,
      department: department,
      minutesEstimate: minutesEstimate,
      minutesReported: minutesReported,
      timeZone: tz,
    }
    console.log(myVariables)
  }
  const handleCompleted = event => {
    setCompleted(event.target.checked)
  }

  const handleClear = event => {
    setTitle("")
    setCompletedDate(null)
    setMinutesEstimate(null)
    setMinutesReported(null)
    setCategoryId("")
    setAssignedToId(1)
    setTitle("")
    setPriority(1)
    setCompleted(false)
    setNote("")
    setDueDate("")
    setAssignedToId(null)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return "something is wrong"
  if (data == null) {
    return "something is wrong"
  }
  const staff = data.staff
  const categories = data.taskCategories
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Card className={clsx(classes.root)} {...rest}>
            <CardHeader
              className={classes.header}
              title={
                <Typography
                  display="block"
                  variant="overline"
                  color="textSecondary"
                >
                  New Task
                </Typography>
              }
            />
            <Box m={2}>
              <form
                onSubmit={event => {
                  event.preventDefault()
                  handleSubmit(event)
                }}
                className={clsx(classes.root)}
                {...rest}
              >
                <TextField
                  fullWidth
                  label="Task Name"
                  name="taskTitle"
                  value={title}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  className={classes.field}
                  onChange={event => setTitle(event.target.value)}
                />

                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  value={note}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  className={classes.field}
                  onChange={event => setNote(event.target.value)}
                />

                <TextField
                  id="datetime-local"
                  label="Due Date"
                  type="datetime-local"
                  variant="outlined"
                  className={classes.field}
                  fullWidth
                  value={dueDate}
                  onChange={event => setDueDate(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {/* <KeyboardDatePicker
                className={classes.datePicker}
                label="Start Date"
                format="MM/DD/YYYY"
                name="startDate"
                inputVariant="outlined"
                value={values.startDate}
                onBlur={() => setFieldTouched('startDate')}
                onClose={() => setFieldTouched('startDate')}
                onAccept={() => setFieldTouched('startDate')}
                onChange={(date) => setFieldValue('startDate', date)}
              /> */}
                <TextField
                  label="Department"
                  id="department"
                  onChange={event => setDepartment(event.target.value)}
                  select
                  margin="dense"
                  size="small"
                  fullWidth
                  variant="outlined"
                  className={classes.field}
                  value={department}
                >
                  <MenuItem value={null}>
                    <em>Select a Department</em>
                  </MenuItem>
                  {taskDepartments.map((dep, index) => {
                    const description = dep.description
                    const name = dep.name
                    return (
                      <MenuItem key={index} value={name}>
                        {description}
                      </MenuItem>
                    )
                  })}
                </TextField>
                <TextField
                  label="Category"
                  id="category"
                  onChange={event => setCategoryId(event.target.value)}
                  select
                  margin="dense"
                  size="small"
                  fullWidth
                  variant="outlined"
                  className={classes.field}
                  value={categoryId}
                >
                  <MenuItem value={null}>
                    <em>Select a Category</em>
                  </MenuItem>
                  {categories.map(cat => {
                    const catId = parseInt(cat.id)
                    return (
                      <MenuItem key={cat.id} value={catId}>
                        {catId} {cat.name}
                      </MenuItem>
                    )
                  })}
                </TextField>

                <TextField
                  id="assigned-to"
                  value={assignedToId}
                  select
                  margin="dense"
                  size="small"
                  onChange={event => setAssignedToId(event.target.value)}
                  label="Assigned To"
                  variant="outlined"
                  className={classes.field}
                  fullWidth
                >
                  <MenuItem value="Select a Staff Member">
                    <em>Select a Staff Member</em>
                  </MenuItem>

                  {staff.map(st => {
                    const staffId = parseInt(st.id)
                    return (
                      <MenuItem key={st.id} value={staffId}>
                        {st.person.firstName} {st.person.lastName}
                      </MenuItem>
                    )
                  })}
                </TextField>

                <TextField
                  label="Priority"
                  id="task-priority"
                  select
                  value={priority}
                  margin="dense"
                  size="small"
                  onChange={event => setPriority(event.target.value)}
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value={1}>1 - Highest Priority</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5 - Lowest Priority</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Minutes to Complete (estimate)"
                  value={minutesEstimate}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  type="number"
                  className={classes.field}
                  onChange={event =>
                    setMinutesEstimate(parseInt(event.target.value))
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={completed}
                      onChange={handleCompleted}
                      name="completed"
                      color="primary"
                    />
                  }
                  label="Already Completed"
                />

                <Collapse in={completed} timeout="auto" unmountOnExit>
                  <TextField
                    id="datetime-local-completed"
                    label="Completed Date"
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    value={completedDate}
                    onChange={event => setCompletedDate(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      native: true,
                    }}
                    className={classes.field}
                  />

                  <TextField
                    fullWidth
                    label="Minutes to Complete (reported)"
                    value={minutesReported}
                    variant="outlined"
                    className={classes.field}
                    margin="dense"
                    size="small"
                    type="number"
                    onChange={event =>
                      setMinutesReported(parseInt(event.target.value))
                    }
                  />
                </Collapse>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    onClick={handleClear}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={submitting}
                  >
                    Cancel
                  </Button>

                  <Button
                    color="secondary"
                    disabled={submitting}
                    type="submit"
                    variant="contained"
                    size="small"
                  >
                    Add Task
                  </Button>
                </Box>
              </form>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: "space-between",
  },
  addTab: {
    marginLeft: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  datePicker: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
})

const TASK_FORM_QUERY = gql`
  query {
    staff {
      id
      person {
        uid
        firstName
        lastName
      }
    }
    taskCategories {
      id
      name
    }
  }
`

const CREATE_TASK_MUTATION = gql`
  mutation (
    $title: String!
    $categoryId: Int
    $projectId: Int
    $orderId: Int
    $itemId: Int
    $quoteId: Int
    $supplierUid: String
    $dueDate: DateTime
    $completedDate: DateTime
    $completed: Boolean
    $assignedToId: Int
    $note: String
    $priority: Int
    $position: Int
    $department: String
    $minutesEstimate: Int
    $minutesReported: Int
    $timeZone: String
  ) {
    createTask(
      title: $title
      categoryId: $categoryId
      projectId: $projectId
      orderId: $orderId
      itemId: $itemId
      quoteId: $quoteId
      supplierUid: $supplierUid
      dueDate: $dueDate
      completedDate: $completedDate
      completed: $completed
      assignedToId: $assignedToId
      note: $note
      priority: $priority
      position: $position
      department: $department
      minutesEstimate: $minutesEstimate
      minutesReported: $minutesReported
      timeZone: $timeZone
    ) {
      task {
        id
        title
        position
        dependsOnTask {
          id
        }
        category {
          id
          name
        }
        assignedTo {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        createdDate
        dueDate
        taskOverdueStatus
        completed
        completedDate
        createdBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        minutesEstimate
        minutesReported
        priority
        note
        projectTask: taskForProject {
          project {
            id
            title
          }
        }
        quoteTask {
          id
          quote {
            id
            createdAt
            businessUnit {
              id
              name
            }
            likelihood
          }
        }
        orderTask {
          id
          order {
            id
            customer {
              uid
              firstName
              lastName
            }
            completed
            businessUnit {
              id
              name
            }
          }
        }
        itemTask: taskItem {
          id
          item {
            id
            name
            privateItem {
              itemOwner {
                uid
                firstName
                lastName
              }
              isPrereg
            }
            category {
              id
              name
            }
            featuredImageThumbnailUrl
          }
        }
      }
    }
  }
`
export default withStyles(styles)(NewTask)
