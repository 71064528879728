export default function reducer(state, action) {
  switch (action.type) {
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      }
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      }
    case "SET_PAGE_COUNT":
      return {
        ...state,
        pageCount: action.payload,
      }
    default:
      return state
  }
}
