import React, { useState } from "react"
import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Page from "../../../Global/Page"
import Header from "./Header"

import PerfectScrollbar from "react-perfect-scrollbar"
const tabs = [
  { value: "details", label: "Details" },
  { value: "account", label: "Account" },
  { value: "tasks", label: "Tasks" },
  { value: "schedule", label: "Schedule" },
]
const ManageStaffDetailsView = ({ classes }) => {
  const [currentTab, setCurrentTab] = useState("details")
  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }
  return (
    <Page className={classes.root} title="Manage Staff">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
              className={classes.tabs}
            >
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box mt={3}>
            {currentTab === "details" && "Details"}
            {currentTab === "account" && "Account"}
            {currentTab === "tasks" && "Tasks"}
            {currentTab === "schedule" && "Schedule"}
          </Box>
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
})
export default withStyles(styles)(ManageStaffDetailsView)
