import React, { useState, useContext } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Header from "./Header"
import Results from "./Results"
import PerfectScrollbar from "react-perfect-scrollbar"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import SortContext from "../../../../context/SortContext"
import Page from "../../../Global/Page"

const SupplierBatchListView = ({ classes }) => {
  const page = 1
  const search = ""
  const pageSize = 10
  const { loading, error, data } = useQuery(ITEM_BATCH_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const res = data.itemBatches

  return (
    <Page className={classes.root} title="Supplier Categories">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          {/* {JSON.stringify(data)} */}
          <Results res={res} />
        </Box>
      </Container>
    </Page>
  )
}

const ITEM_BATCH_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String) {
    itemBatches(page: $page, pageSize: $pageSize, search: $search) {
      page
      page
      count
      hasNext
      hasPrev
      objects {
        id
        expectedArrival
        actualArrival
        supplier {
          ouid
          name
        }
        batchStatus {
          id
          name
        }
        shipFrom {
          ouid
          name
        }
        carrier {
          ouid
          name
        }
        tracking
        shipTo {
          uid
          firstName
          lastName
        }
      }
    }
  }
`
const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    height: "100%",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
})
export default withStyles(styles)(SupplierBatchListView)
