import React, { useState, useEffect, useContext } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import TasksContext from "../../../../../context/TasksContext"
import withStyles from "@material-ui/core/styles/withStyles"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { useLocation } from "@reach/router"
import {
  Filter as FilterIcon,
  Move as DelegateIcon,
  PieChart as StatsIcon,
  List as ListIcon,
  Plus as AddIcon,
  RefreshCw as RefreshIcon,
} from "react-feather"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import AssignTasks from "./AssignTasks"
import PositionTasks from "./PositionTasks"
import TaskStats from "./TaskStats"
import FilterTasks from "./FilterTasks"
import NewTask from "./NewTask"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
const grid = 8

const CompleteTask = React.memo(function CompleteTask({ task }) {
  return (
    <Box
      style={{
        border: "2px #d3d3d3 dashed",
        padding: "10px",
        marginBottom: "5px",
        textAlign: "center",
      }}
    >
      <Typography>Completed Task</Typography>
    </Box>
  )
})

const TaskManager = ({ classes, view, setView }) => {
  const { state, dispatch } = useContext(TasksContext)
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const [taskId, setTaskId] = useState(null)
  const [selTask, setSelTask] = useState("")
  const [newPosition, setNewPosition] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const page = 1
  const pageSize = 15
  const search = ""
  // const page = state.page ? state.page : 1
  // const pageSize = state.pageSize ? state.page : 15
  // const search = state.search ? state.search : ""
  // const [tasks, setTasks] = useState([])

  useEffect(() => {
    dispatch({ type: "SET_USER_PERM", payload: userPermission })
  }, [])

  const [columns, setColumns] = useState([
    { id: "column-1", title: "Colunm 1", tasks: [] },
    { id: "column-2", title: "Colunm 2", tasks: [] },
  ])

  const { data, loading, error } = useQuery(TASK_MANAGER_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      completedFilter: false,
      fetchPolicy: "cache-and-network",
    },
    onCompleted: data => {
      const uploadedTasks = data.taskManager.objects
      const sortedTasks = uploadedTasks.slice().sort(function (a, b) {
        return a.position - b.position
      })
      dispatch({ type: "SET_TASKS", payload: sortedTasks })
      // dispatch({ type: "SET_PAGE", payload: data.taskManager.page })
      // dispatch({ type: "SET_PAGE_COUNT", payload: data.taskManager.pages })
      // dispatch({ type: "SET_TASK_COUNT", payload: data.taskManager.count })
      console.log(sortedTasks)
      // setTasks(sortedTasks)
    },
  })
  const [refreshTasks] = useLazyQuery(TASK_MANAGER_QUERY, {
    variables: {
      page: 1,
      pageSize: 10,
      search: "",
      completedFilter: false,
      fetchPolicy: "network-only",
    },
    onCompleted: data => {
      const uploadedTasks = data.taskManager.objects
      const sortedTasks = uploadedTasks.slice().sort(function (a, b) {
        return a.position - b.position
      })
      // setTasks(sortedTasks)
      dispatch({ type: "SET_TASKS", payload: sortedTasks })
      console.log("refreshed tasks")
    },
  })
  const tasks = state.tasks
  function getView(view) {
    switch (view) {
      case "complete":
        return <CompleteTask task={selTask} />
      case "assign":
        return <AssignTasks />
      case "list":
        return <PositionTasks />
      case "new":
        return <NewTask />
      case "stats":
        return <TaskStats />
      default:
        return <AssignTasks />
    }
  }
  const handleSelectTask = () => {
    console.log(selTask)
  }
  const handleRefreshTasks = () => {
    console.log("should refresh")
    refreshTasks()
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <>
      <Card>
        <CardActions>
          <IconButton
            className={
              view === "list" ? classes.activeButton : classes.inactiveButton
            }
            onClick={() => setView("list")}
          >
            <SvgIcon>
              <ListIcon />
            </SvgIcon>
          </IconButton>
          <IconButton
            className={
              showFilters ? classes.activeButton : classes.inactiveButton
            }
            onClick={() => setShowFilters(!showFilters)}
          >
            <SvgIcon>
              <FilterIcon />
            </SvgIcon>
          </IconButton>
          <IconButton
            className={
              view === "assign" ? classes.activeButton : classes.inactiveButton
            }
            onClick={() => setView("assign")}
          >
            <SvgIcon>
              <DelegateIcon />
            </SvgIcon>
          </IconButton>
          <IconButton
            className={
              view === "stats" ? classes.activeButton : classes.inactiveButton
            }
            onClick={() => setView("stats")}
          >
            <SvgIcon>
              <StatsIcon />
            </SvgIcon>
          </IconButton>
          <IconButton
            className={
              view === "new" ? classes.activeButton : classes.inactiveButton
            }
            onClick={() => setView("new")}
          >
            <SvgIcon>
              <AddIcon />
            </SvgIcon>
          </IconButton>
          <IconButton onClick={handleRefreshTasks}>
            <SvgIcon>
              <RefreshIcon />
            </SvgIcon>
          </IconButton>
          <Box className={classes.typeView}>
            <Typography>
              {view === "assign"
                ? "Assign Tasks"
                : view === "list"
                ? "Sort Tasks"
                : view === "new"
                ? "New Task"
                : ""}
            </Typography>
          </Box>
        </CardActions>
      </Card>
      {showFilters && <FilterTasks />}
      {getView(view)}
    </>
  )
}

const styles = theme => ({
  root: {},
  taskCard: {
    width: "200px",
    marginBottom: `${grid}px`,
    padding: `${grid}px`,
  },
  completeBox: {
    width: "200px",
    height: "200px",
    marginBottom: `${grid}px`,
    padding: `${grid}px`,
  },
  activeButton: {
    color: theme.palette.text.success,
  },
  inactiveButton: {},
  typeView: {
    marginLeft: "auto",
  },
})
const TASK_MANAGER_QUERY = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $search: String
    $completedFilter: Boolean
  ) {
    taskManager(
      page: $page
      pageSize: $pageSize
      search: $search
      completedFilter: $completedFilter
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        title
        position
        assignedTo {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        createdDate
        dueDate
        taskOverdueStatus
        completed
        completedDate
        createdBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        minutesEstimate
        minutesReported
        priority
        note
        projectTask: taskForProject {
          id
          project {
            id
          }
        }
        quoteTask {
          id
          quote {
            id
          }
        }
        orderTask {
          id
          order {
            id
          }
        }
        itemTask: taskItem {
          id
          item {
            id
          }
        }
      }
    }
  }
`
export default withStyles(styles)(TaskManager)
