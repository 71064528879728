import React, { useState, useEffect, useContext, useReducer } from "react"
import SupplierOrderContext from "./SupplierOrderContext"
import SupplierOrderReducer from "./SupplierOrderReducer"

const SupplierOrderProvider = props => {
  const initialState = useContext(SupplierOrderContext)
  const [localState, setLocalState] = useState("")
  const isBrowser = () => typeof window !== "undefined"
  useEffect(() => {
    isBrowser && setLocalState(JSON.parse(window.localStorage.getItem("so")))
  }, [])
  const [state, dispatch] = useReducer(
    SupplierOrderReducer,
    localState || initialState
  )
  useEffect(() => {
    isBrowser && window.localStorage.setItem("tsk", JSON.stringify(state))
  }, [state])

  return (
    <SupplierOrderContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SupplierOrderContext.Provider>
  )
}

export default SupplierOrderProvider
