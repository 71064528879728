import React, { useState } from "react"
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import clsx from "clsx"
import {
  ArrowLeft as BackArrowIcon,
  ArrowRight as ForwardArrowIcon,
  Calendar as CalendarIcon,
} from "react-feather"
const ChangeWeek = () => {
  return (
    <span style={{ display: "inline" }}>
      <IconButton size="small">
        <SvgIcon color="primary" fontSize="small">
          <BackArrowIcon />
        </SvgIcon>
      </IconButton>
      Week
      <IconButton size="small">
        <SvgIcon color="primary" fontSize="small">
          <ForwardArrowIcon />
        </SvgIcon>
      </IconButton>
    </span>
  )
}
const StaffScheduleQuickView = ({ classes, ...rest }) => {
  return (
    <Card className={clsx(classes.root, classes.roundedCard)} {...rest}>
      <CardHeader
        title="Staff Schedule"
        action={
          <IconButton>
            <SvgIcon color="primary" fontSize="small">
              <CalendarIcon />
            </SvgIcon>
          </IconButton>
        }
      />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">
              <ChangeWeek />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Person Name</TableCell>
            <TableCell align="right">MTWRFSS</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  roundedCard: {
    borderRadius: "15px",
    width: "100%",
  },
})
export default withStyles(styles)(StaffScheduleQuickView)
