import React, { useState } from "react"
import clsx from "clsx"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"

const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
  table: {
    marginTop: 5,
    marginBottom: 10,
  },
}))

const Categories = ({ className, ...rest }) => {
  const classes = useStyles()
  return (
    <>
      <PerfectScrollbar>
        <Box minWidth={1200}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Thing</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Thing</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Thing</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Thing</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Thing</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Thing</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  )
}

export default Categories
