import React, { useState } from "react"
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"
import Page from "../../../Global/Page"
import Header from "./Header"
import { Link } from "gatsby"

import TaskManager from "./TaskManager"
// import EarningsSegmentation from "./EarningsSegmentation"
// import LatestOrders from "./LatestOrders"
// import LatestProjects from "./LatestProjects"
// import NewProjects from "./NewProjects"
// import PerformanceOverTime from "./PerformanceOverTime"
// import RealTime from "./RealTime"
// import RoiPerCustomer from "./RoiPerCustomer"
// import SystemHealth from "./SystemHealth"
// import TeamTasks from "./TeamTasks"
// import TodaysMoney from "./TodaysMoney"
// import ItemTasks from "./ItemTasks"
// import OrderTasks from "./OrderTasks"
// import QuoteTasks from "./QuoteTasks"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const TasksManagerView = () => {
  const classes = useStyles()
  const [view, setView] = useState("list")
  return (
    <Page className={classes.root} title="Tasks - Manager View">
      <Container maxWidth={false}>
        <Header view={view} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <TaskManager view={view} setView={setView} />
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item sm={6} xs={12}>
            <ItemTasks />
            <QuoteTasks />
          </Grid> */}
          {/* <Grid item sm={6} xs={12}> */}
          {/* <OrderTasks /> */}

          {/* </Grid> */}

          {/* <Grid item lg={3} sm={6} xs={12}>
            <SystemHealth />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <RoiPerCustomer />
          </Grid>
          <Grid item lg={3} xs={12}>
            <RealTime />
          </Grid>
          <Grid item lg={9} xs={12}>
            <PerformanceOverTime />
          </Grid>
          <Grid item lg={5} xl={4} xs={12}>
            <TeamTasks />
          </Grid>
          <Grid item lg={7} xl={8} xs={12}>
            <LatestProjects />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
}

export default TasksManagerView
