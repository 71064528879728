import React from "react"
import Items from "./Items"
import { Card, CardHeader, Divider, makeStyles } from "@material-ui/core"

const Details = ({ sbu, section }) => {
  const sectionSwitch = section => {
    switch (section) {
      case "items":
        return <Items />
      case "orders":
        return "orders"
      case "customers":
        return "customers"
      case "suppliers":
        return "suppliers"
      case "campaigns":
        return "campaigns"
      case "strategy":
        return "strategy"
      case "metrics":
        return "metrics"
      default:
        return "default view"
    }
  }
  return (
    <>
      <div>{sectionSwitch(section)}</div>
    </>
  )
}

export default Details
