import React, { useCallback, useEffect, useState } from "react"
import { Box, Container, Grid, makeStyles } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Page from "../../../Global/Page"
import Header from "./Header"
import BusinessUnitList from "./BusinessUnitList"
import Details from "./Details"
// import CartItems from "./CartItems"
// import AddOrderItem from "./AddOrderItem"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const BusinessUnitMainView = ({ className, ...rest }) => {
  const classes = useStyles()
  const [section, setSection] = useState("items")
  const [search, setSearch] = useState(false)
  const [createNew, setCreateNew] = useState(false)
  const [sbu, setSbu] = useState(0)

  // const sbuSwitch = businessUnit => {
  //   switch (businessUnit) {
  //     case "i":
  //       return <ConversationList />
  //     case "newInvites":
  //       return <InviteList />
  //     case "conversationSelected":
  //       return <ConversationDetails />
  //     case "conversationNew":
  //       return <ConversationNew />
  //     default:
  //       return "conversationList"
  //   }
  // }
  const { data, loading, error } = useQuery(BUSINESS_UNIT_QUERY, {
    onCompleted: data => {
      setSbu(data.sbus[0])
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const businessUnits = data.sbus

  return (
    <Page className={classes.root} title="Business Units">
      <Container maxWidth={false}>
        <Header />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item md={4} xl={3} xs={12}>
              <BusinessUnitList
                businessUnits={businessUnits}
                sbu={sbu}
                setSbu={setSbu}
                section={section}
                setSection={setSection}
              />
            </Grid>
            <Grid item md={8} xl={9} xs={12}>
              <Details sbu={sbu} section={section} setSection={setSection} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

const BUSINESS_UNIT_QUERY = gql`
  query {
    sbus {
      id
      name
      homepage
      primaryEmail
      description
    }
  }
`
export default BusinessUnitMainView
