import React, { useState } from "react"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
const HomePageMessages = ({ classes, index }) => {
  return (
    <Draggable draggableId="messages" index={index}>
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          sm={4}
          index={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card className={classes.homeCard}>
            <CardHeader title="Messages" />
            <CardContent>Messages</CardContent>
          </Card>
        </Grid>
      )}
    </Draggable>
  )
}

const styles = theme => ({
  root: {},
  homeCard: {
    height: "26rem",
    borderRadius: "15px",
  },
})
export default withStyles(styles)(HomePageMessages)
