import React from "react"
import { Router } from "@reach/router"
import UserProvider from "../context/UserProvider"
import ManagerRoute from "../components/Route/ManagerRoute"
import MessagesProvider from "../components/Users/Messages/context/MessagesProvider"
import SupplierOrderProvider from "../context/SupplierOrderProvider"
import TasksProvider from "../context/TasksProvider"
import BusinessUnitMainView from "../components/Manager/BusinessUnits/BusinessUnitMainView"
import ItemListView from "../components/Manager/Items/ItemListView"
import ItemDetailsView from "../components/Manager/Items/ItemDetailsView"
import ItemCreateView from "../components/Manager/Items/ItemCreateView"
import ItemProductionView from "../components/Manager/Items/ItemProductionView"

import InquiryDetailsView from "../components/Manager/Inquiries/InquiryDetailsView"
import InquiryListView from "../components/Manager/Inquiries/InquiryListView"
import ManageStaffDashboardView from "../components/Manager/ManageStaff/ManageStaffDashboardView"
import ManageStaffDetailsView from "../components/Manager/ManageStaff/ManageStaffDetailsView"
import PeopleListView from "../components/Manager/People/PeopleListView"
import PeopleDetailsView from "../components/Manager/People/PeopleDetailsView"
import PeopleCreateView from "../components/Manager/People/PeopleCreateView"
import Accounting from "../components/Manager/Accounting"
import PeopleManagerView from "../components/Manager/People/PeopleManagerView"
import OrgListView from "../components/Manager/Organizations/OrgListView"
import OrgCreateView from "../components/Manager/Organizations/OrgCreateView"
import OrgDetailsView from "../components/Manager/Organizations/OrgDetailsView"
import UserListView from "../components/Manager/Users/UserListView"
import OrderListView from "../components/Manager/Orders/OrderListView"
import OrderDetailsView from "../components/Manager/Orders/OrderDetailsView"
import OrderCreateView from "../components/Manager/Orders/OrderCreateView"
import SupplierBatchDetailsView from "../components/Manager/Supply/SupplierBatchDetailsView"

import SupplierBatchListView from "../components/Manager/Supply/SupplierBatchListView"
import SupplierCategoryView from "../components/Manager/Supply/SupplierCategoryView"
import CartDetailsView from "../components/Manager/Carts/CartDetailsView"
import CartListView from "../components/Manager/Carts/CartListView"
// import PaymentsListView from "../components/Manager/Accounting/Payments/PaymentsListView"
// import PaymentDetailsView from "../components/Manager/Accounting/Payments/PaymentDetailsView"
import SortConducer from "../context/SortConducer"
import Upload from "../components/Upload"
// import Orders from "../components/Manager/Orders/Orders"
import Vendors from "../components/Manager/Vendors"
import Projects from "../components/Manager/Projects"
import PlanListView from "../components/Manager/Plans/PlanListView"
import PlanDetailsView from "../components/Manager/Plans/PlanDetailsView"

import Files from "../components/Users/Files"
import ProjectDetailsView from "../components/Manager/Projects/ProjectDetails/"
import ProjectCreateView from "../components/Manager/Projects/ProjectCreateView"
import SupplierOrderListView from "../components/Manager/Supply/SupplierOrderListView"
import SupplierOrderDetailsView from "../components/Manager/Supply/SupplierOrderDetailsView"
import SupplierListView from "../components/Manager/Supply/SupplierListView"
import SupplierDetailsView from "../components/Manager/Supply/SupplierDetailsView"
import SupplierBillsPaymentsView from "../components/Manager/Supply/SupplierBillsPaymentsView"
import ManagerHome from "../components/Manager/ManagerHome"
import ProposalListView from "../components/Manager/Proposals/ProposalListView"
import ProposalDetailsView from "../components/Manager/Proposals/ProposalDetailsView"
import ProposalCreateView from "../components/Manager/Proposals/ProposalCreateView"
import ServiceHomeView from "../components/Manager/Service/ServiceHomeView"
import TasksManagerView from "../components/Manager/ManagerHome/TasksManagerView"

import Profile from "../components/Users/Profile"
import Messages from "../components/Users/Messages"
import DocsListView from "../components/Users/Docs/DocsListView"
import DocsDetailsView from "../components/Users/Docs/DocsDetailsView"
import DocsPublishView from "../components/Users/Docs/DocsPublishView"
import "react-perfect-scrollbar/dist/css/styles.css"
import DashboardLayout from "../components/DashboardLayout"
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import useSettings from "../hooks/useSettings"
import MomentUtils from "@date-io/moment"
import { createTheme } from "../theme"
import { create } from "jss"
import { SnackbarProvider } from "notistack"

const jss = create({ plugins: [...jssPreset().plugins] })
const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
)

const Manager = () => {
  useStyles()
  const settings = useSettings()

  return (
    <UserProvider>
      <ThemeProvider theme={createTheme(settings)}>
        <TasksProvider>
          <MessagesProvider>
            <SupplierOrderProvider>
              <SortConducer>
                <StylesProvider jss={jss}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider maxSnack={1}>
                      <DashboardLayout>
                        <Router>
                          <ManagerRoute
                            path="/manager"
                            component={ManagerHome}
                          />

                          {/* Todo: Usertype-specific messages views. This one is manager view */}
                          <ManagerRoute
                            path="/manager/messages"
                            component={Messages}
                          />
                          <ManagerRoute
                            path="/manager/docs"
                            component={DocsListView}
                          />
                          <ManagerRoute
                            path="/manager/docs/:id"
                            component={DocsDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/docs/new"
                            component={DocsPublishView}
                          />
                          <ManagerRoute
                            path="/manager/upload"
                            component={Upload}
                          />

                          <ManagerRoute
                            path="/manager/people"
                            component={PeopleListView}
                          />
                          <ManagerRoute
                            path="/manager/people/create"
                            component={PeopleCreateView}
                          />

                          <ManagerRoute
                            path="/manager/people/:uid"
                            component={PeopleDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/people/manager"
                            component={PeopleManagerView}
                          />
                          <ManagerRoute
                            path="/manager/managestaff"
                            component={ManageStaffDashboardView}
                          />
                          <ManagerRoute
                            path="/manager/managestaff/:uid"
                            component={ManageStaffDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/orgs"
                            component={OrgListView}
                          />
                          <ManagerRoute
                            path="/manager/orgs/:ouid"
                            component={OrgDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/orgs/new"
                            component={OrgCreateView}
                          />
                          <ManagerRoute
                            path="/manager/user"
                            component={UserListView}
                          />
                          <ManagerRoute
                            path="/manager/items"
                            component={ItemListView}
                          />
                          <ManagerRoute
                            path="/manager/items/:id"
                            component={ItemDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/items/create"
                            component={ItemCreateView}
                          />
                          <ManagerRoute
                            path="/manager/items/production"
                            component={ItemProductionView}
                          />
                          <ManagerRoute
                            path="/manager/carts"
                            component={CartListView}
                          />
                          <ManagerRoute
                            path="/manager/carts/:id"
                            component={CartDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/orders"
                            component={OrderListView}
                          />
                          <ManagerRoute
                            path="/manager/orders/:id"
                            component={OrderDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/orders/new"
                            component={OrderCreateView}
                          />
                          <ManagerRoute
                            path="/manager/accounting"
                            component={Accounting}
                          />
                          {/* <ManagerRoute
                        path="/manager/accounting/payments"
                        component={PaymentsListView}
                      />
                      <ManagerRoute
                        path="/manager/accounting/payments/:id"
                        component={PaymentDetailsView}
                      /> */}
                          <ManagerRoute
                            path="/manager/inquiries"
                            component={InquiryListView}
                          />
                          <ManagerRoute
                            path="/manager/inquiries/:id"
                            component={InquiryDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/projects"
                            component={Projects}
                          />
                          <ManagerRoute
                            path="/manager/projects/:id"
                            component={ProjectDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/projects/create"
                            component={ProjectCreateView}
                          />
                          <ManagerRoute
                            path="/manager/plans"
                            component={PlanListView}
                          />
                          <ManagerRoute
                            path="/manager/plans/:id"
                            component={PlanDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/vendors"
                            component={Vendors}
                          />
                          <ManagerRoute
                            path="/manager/supply/suppliers"
                            component={SupplierListView}
                          />
                          <ManagerRoute
                            path="/manager/supply/suppliers/:ouid"
                            component={SupplierDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/supply/orders/"
                            component={SupplierOrderListView}
                          />
                          <ManagerRoute
                            path="/manager/supply/bills"
                            component={SupplierBillsPaymentsView}
                          />
                          <ManagerRoute
                            path="/manager/supply/orders/:id"
                            component={SupplierOrderDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/supply/batches"
                            component={SupplierBatchListView}
                          />

                          <ManagerRoute
                            path="/manager/supply/batches/:id"
                            component={SupplierBatchDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/supply/suppliers/categories"
                            component={SupplierCategoryView}
                          />
                          <ManagerRoute
                            path="/manager/conversations"
                            component={Messages}
                          />
                          <ManagerRoute
                            path="/manager/conversations/:id"
                            component={Messages}
                          />
                          {/* <ManagerRoute path="/manager/tasks" component={Tasks} /> */}
                          <ManagerRoute
                            path="/manager/files"
                            component={Files}
                          />
                          <ManagerRoute
                            path="/manager/profile"
                            component={Profile}
                          />
                          <ManagerRoute
                            path="/manager/proposals"
                            component={ProposalListView}
                          />
                          <ManagerRoute
                            path="/manager/proposals/new"
                            component={ProposalCreateView}
                          />
                          <ManagerRoute
                            path="/manager/proposals/:id"
                            component={ProposalDetailsView}
                          />
                          <ManagerRoute
                            path="/manager/service"
                            component={ServiceHomeView}
                          />
                          <ManagerRoute
                            path="/manager/tasks"
                            component={TasksManagerView}
                          />
                          <ManagerRoute
                            path="/manager/sbu"
                            component={BusinessUnitMainView}
                          />
                        </Router>
                      </DashboardLayout>
                    </SnackbarProvider>
                  </MuiPickersUtilsProvider>
                </StylesProvider>
              </SortConducer>
            </SupplierOrderProvider>
          </MessagesProvider>
        </TasksProvider>
      </ThemeProvider>
    </UserProvider>
  )
}

export default Manager
