import React, { useState, useContext } from "react"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import TasksContext from "../../../../../../context/TasksContext"
import AssignedTaskCard from "./AssignedTaskCard"
import { Droppable, Draggable } from "react-beautiful-dnd"
import clsx from "clsx"

const StaffCard = ({ classes, stf, index }) => {
  const { state, dispatch } = useContext(TasksContext)
  //   const taskItems = state.tasks.map(function (el) {
  //     var o = Object.assign({}, el)
  //     o.gridType = "taskItem"
  //     return o
  //   })
  const staffTasks = state.staffTasks
  const page = 1
  const pageSize = 15
  const search = ""
  const { data, loading, error } = useQuery(STAFF_TASK_QUERY, {
    variables: {
      staffId: parseInt(stf.id),
      page: page,
      pageSize: pageSize,
      search: search,
      //   completedFilter: false,
    },
    onCompleted: data => {
      const myTaskObjects = data.taskManager.objects
      const myTasks = { staffId: stf.id, tasks: myTaskObjects }
      const updatedTasks = [...staffTasks, myTasks]
      dispatch({ type: "SET_STAFF_TASKS", payload: updatedTasks })
    },
  })

  const name =
    stf && stf.person ? stf.person.firstName + " " + stf.person.lastName : ""
  //   const staffTasks =
  //     stf.id === "0"
  //       ? tasks.filter(tsk => tsk.assignedTo === null)
  //       : tasks.filter(ts => ts.assignedTo && ts.assignedTo.id == stf.id)

  if (loading)
    return (
      <Grid item xs={12} sm={4} key={stf.id}>
        <Card className={classes.staffCard}>
          <CardHeader title={name} />
          <CardContent>
            <div className={classes.loadingDiv}>
              <CircularProgress color="secondary" />
            </div>
          </CardContent>
        </Card>
      </Grid>
    )
  if (error) return "ERROR"
  const myStaffTasks = staffTasks && staffTasks.find(t => t.staffId == stf.id)
  const myTasks = myStaffTasks ? myStaffTasks.tasks : []
  return (
    <Draggable draggableId={`list-drag-${stf.id}`} index={index}>
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          sm={4}
          key={stf.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card className={classes.staffCard}>
            <CardHeader title={name} {...provided.dragHandleProps} />
            <CardContent>
              {/* <Typography>snapshot: {JSON.stringify(snapshot)}</Typography> */}
              {/* <Typography>{JSON.stringify(typeof myStaffTasks)}</Typography>
              <Typography>{JSON.stringify(myTasks)}</Typography> */}

              <Droppable
                droppableId={`list-drop-${stf.id}`}
                type={`droppableTask`}
                className={
                  snapshot.isDraggingOver
                    ? clsx(classes.dragOver, classes.dragBackground)
                    : classes.dragBackground
                }
              >
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {myTasks ? (
                      myTasks.map((tsk, i) => {
                        return <AssignedTaskCard key={i} tsk={tsk} index={i} />
                      })
                    ) : (
                      <Typography>No Staff Tasks</Typography>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Draggable>
  )
}

const styles = theme => ({
  root: {},
  staffCard: {
    width: "100%",
    minHeight: "300px",
    marginTop: theme.spacing(1),
    borderRadius: "15px",
    boxShadow: "0 2px 4px 2px rgba(0,0,0, .08)",
  },
  taskCard: {
    marginTop: theme.spacing(1),
    borderRadius: "15px",
    boxShadow: "0 2px 4px 2px rgba(0,0,0, .15)",
  },
  draggingTask: {
    backgroundColor: "#ADD8E6",
  },
  dragOver: {
    backgroundColor: "#ADD8E6",
  },
  dragBackground: {},
  loadingDiv: {
    margin: "auto",
  },
})
const STAFF_TASK_QUERY = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $search: String
    $completedFilter: Boolean
    $staffId: Int
  ) {
    taskManager(
      page: $page
      pageSize: $pageSize
      search: $search
      completedFilter: $completedFilter
      staffId: $staffId
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        title
        position
        assignedTo {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        createdDate
        dueDate
        taskOverdueStatus
        completed
        completedDate
        createdBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        minutesEstimate
        minutesReported
        priority
        note
        projectTask: taskForProject {
          id
          project {
            id
          }
        }
        quoteTask {
          id
          quote {
            id
          }
        }
        orderTask {
          id
          order {
            id
          }
        }
        itemTask: taskItem {
          id
          item {
            id
          }
        }
      }
    }
  }
`
export default withStyles(styles)(StaffCard)
