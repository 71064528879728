import React, { useState, useEffect, useContext } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import withStyles from "@material-ui/core/styles/withStyles"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import UndoIcon from "@material-ui/icons/Undo"
import CloseIcon from "@material-ui/icons/Close"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { truncate } from "lodash"
import { Eye as ViewIcon, Edit as EditIcon } from "react-feather"
import TaskCard from "./TaskCard"
import TasksContext from "../../../../../context/TasksContext"
import moment from "moment"
import TaskDetails from "./TaskDetails"
// import AssignTasks from "./AssignTasks"

const grid = 8
const statusColors = {
  completed: "success",
  overdue: "error",
}
const reorder = (list, startIndex, endIndex) => {
  // copy the list to a new array "result"
  const result = [...list]
  // remove object from "result" array
  const [removed] = result.splice(startIndex, 1)
  //   update "position" property of removed object
  var newPosition = endIndex + 1

  const updatedRemoved = { ...removed, position: newPosition }
  //   insert "removed"object back into array in updated place
  result.splice(endIndex, 0, updatedRemoved)

  // create new array newOrder with updated "position" property in each object
  const newOrder = result.map((res, index) => {
    const newPos = index + 1
    return { ...res, position: newPos }
  })
  console.log(newOrder)
  // return the new array
  return newOrder
}

// const complete = (list, startIndex, endIndex)=>{
//     const result = [...list]
//     const [removed] =
// }withStyles(styles)(TaskManager)

const Task = ({ task, index, onSelectTask, onPickTask, taskId, selTask }) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {provided => (
        <TaskCard
          taskId={taskId}
          selTask={selTask}
          task={task}
          onPickTask={onPickTask}
          onSelectTask={onSelectTask}
          provided={provided}
        />
      )}
    </Draggable>
  )
}
const TaskList = React.memo(function TaskList({
  tasks,
  onSelectTask,
  selTask,
  onPickTask,
  taskId,
}) {
  return tasks.map((task, index) => (
    <Task
      task={task}
      index={index}
      key={task.id}
      onSelectTask={onSelectTask}
      selTask={selTask}
      onPickTask={onPickTask}
      taskId={taskId}
    />
  ))
})

// const AssignTasks = React.memo(function AssignTasks({ users }) {
//   const userBoxes = users.map(user => (
//     <div key={user.id}>
//       <Card>
//         <CardHeader title={user.name} subheader={user.id} />
//         <CardContent>drop tasks here</CardContent>
//       </Card>
//       <Droppable droppableId={`user-${user.id}`}>
//         {provided => (
//           <div ref={provided.innerRef} {...provided.droppableProps}>
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </div>
//   ))
//   return userBoxes
// })

const CompleteTask = React.memo(function CompleteTask({ task }) {
  return (
    <Box
      style={{
        border: "2px #d3d3d3 dashed",
        padding: "10px",
        marginTop: "10px",
        marginBottom: "5px",
        textAlign: "center",
      }}
    >
      <Typography>Complete Task Now</Typography>
    </Box>
  )
})

const PositionTasks = ({ classes }) => {
  const { state, dispatch } = useContext(TasksContext)
  const tasks = state && state.tasks ? state.tasks : []
  const selTask = state && state.selectedTask ? state.selectedTask : ""
  const [taskId, setTaskId] = useState(null)

  const [newPosition, setNewPosition] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [undoAlert, setUndoAlert] = useState("")
  const [view, setView] = useState("assign")
  const [columns, setColumns] = useState([
    { id: "column-1", title: "Colunm 1", tasks: [] },
    { id: "column-2", title: "Colunm 2", tasks: [] },
  ])

  useEffect(() => {
    if ((newPosition && taskId) || (newPosition === 0 && taskId))
      repositionTask()
  }, [newPosition])

  const [repositionTask, { loading, data, error }] = useMutation(
    REPOSITION_TASK_MUTATION,
    {
      variables: {
        taskId: taskId,
        position: newPosition,
        completed: isCompleted,
      },
      onCompleted: data => {
        console.log(data)
        console.log(undoAlert)
        setNewPosition(null)
        setTaskId(null)
        setIsUpdating(false)
        setIsCompleted(false)
      },
    }
  )

  function onDragStart(result) {
    const selectedTask = tasks[result.source.index]
    dispatch({ type: "SET_SELECTED_TASK", payload: selectedTask })

    const selectedTaskId = parseInt(selectedTask.id)
    setTaskId(selectedTaskId)
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return
    }
    if (isUpdating) {
      return
    }

    if (result.destination.droppableId === "completed") {
      setIsCompleted(true)
      var completedAlert = JSON.parse(JSON.stringify(selTask))
      completedAlert = { ...completedAlert, completed: true }
      setUndoAlert(completedAlert)
      const filteredTasks = tasks.filter(t => t.id != taskId)
      const newFilteredOrder = filteredTasks.map((res, index) => {
        const newPos = index + 1
        return { ...res, position: newPos }
      })
      console.log(newFilteredOrder)
      // setTasks(newFilteredOrder)
      dispatch({ type: "SET_TASKS", payload: newFilteredOrder })

      setNewPosition(0)
    }

    if (result.destination.droppableId === "list") {
      var movedAlert = JSON.parse(JSON.stringify(selTask))
      movedAlert = { ...movedAlert, completed: false }
      setUndoAlert(movedAlert)
      const updatedTasks = reorder(
        tasks,
        result.source.index,
        result.destination.index
      )
      const updatedPosition = parseInt(
        updatedTasks.find(t => t.id == taskId).position
      )
      // setTasks(updatedTasks)
      dispatch({ type: "SET_TASKS", payload: updatedTasks })

      setNewPosition(updatedPosition)
    }
  }

  if (loading) return "loading..."
  if (error) return "ERROR"

  //   function getView(view) {
  //     switch (view) {
  //       case "complete":
  //         return <CompleteTask task={selTask} />
  //       case "assign":
  //         return <AssignTasks users={staff} />
  //       default:
  //         return <AssignTasks users={staff} />
  //     }
  //   }
  const handleSelectTask = () => {
    console.log("move")
  }
  const handlePickTask = task => {
    console.log(task)
    dispatch({ type: "SET_SELECTED_TASK", payload: task })
  }
  const handleUndo = event => {
    console.log("handle undo")

    const listCopy = [...tasks]
    const priorIndex = undoAlert.position - 1
    listCopy.splice(priorIndex, 0, undoAlert)
    const updatedOrder = listCopy.map((res, index) => {
      const newPos = index + 1
      return { ...res, position: newPos }
    })
    // setTasks(updatedOrder)
    dispatch({ type: "SET_TASKS", payload: updatedOrder })

    console.log(taskId)
    setNewPosition(undoAlert.position)
    setUndoAlert("")
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Droppable droppableId="completed">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? "#d3d3d3" : "",
                }}
                {...provided.droppableProps}
              >
                <CompleteTask task={selTask} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {undoAlert && (
            <Alert
              action={
                <>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={
                      (() => setTaskId(parseInt(undoAlert.id)),
                      () => setIsCompleted(undoAlert.completed),
                      handleUndo)
                    }
                  >
                    <UndoIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => setUndoAlert("")}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              {undoAlert.completed
                ? `Task "${undoAlert.title}" completed`
                : `Repositioned task "${undoAlert.title}"`}
            </Alert>
          )}
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <TaskList
                  tasks={tasks}
                  onSelectTask={handleSelectTask}
                  selTask={selTask}
                  taskId={taskId}
                  onPickTask={handlePickTask}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Grid>

        <Grid item xs={12} sm={8}>
          {selTask && <TaskDetails />}
        </Grid>
      </Grid>
    </DragDropContext>
  )
}

const styles = theme => ({
  root: {},
  detailCard: {
    marginTop: 10,
  },
  taskCard: {
    width: "200px",
    marginBottom: `${grid}px`,
    padding: `${grid}px`,
    backgroundColor: "#000000",
    textType: { color: theme.palette.text.success },
  },
  completeBox: {
    width: "200px",
    height: "200px",
    marginBottom: `${grid}px`,
    padding: `${grid}px`,
  },
})

const StyledCard = withStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 60,
    padding: "0 10px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    marginBottom: 8,
  },
  label: {
    textTransform: "capitalize",
  },
})(Card)

const REPOSITION_TASK_MUTATION = gql`
  mutation ($taskId: Int!, $position: Int!, $completed: Boolean) {
    positionTask(taskId: $taskId, position: $position, completed: $completed) {
      task {
        id
        title
        position
        completed
      }
    }
  }
`

export default withStyles(styles)(PositionTasks)
