import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { Bar } from "react-chartjs-2"
import { useTheme } from "@material-ui/core"
import PropTypes from "prop-types"
const PastWeekSalesChart = ({ classes, ...rest }) => {
  const theme = useTheme()
  const data = {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        label: "Sales Revenue",
        data: [8000, 10000, 11000, 5000, 2000, 14000, 12500],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(175,128,79, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(175,128,79, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return (
    <div
      position="relative"
      style={{
        width: "42rem",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  )
}

const styles = theme => ({
  root: {},
})
PastWeekSalesChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
}
export default withStyles(styles)(PastWeekSalesChart)
