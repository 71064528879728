import React, { useState } from "react"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import PastWeekSalesChart from "../ManagerCharts/PastWeekSalesChart"
import PerfectScrollbar from "react-perfect-scrollbar"
const HomePageWideChart = ({ classes, index }) => {
  return (
    <Draggable draggableId="wideCharts" index={index}>
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          sm={8}
          index={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <PerfectScrollbar>
            <Card className={classes.homeCard}>
              <CardHeader title="Charts" {...provided.dragHandleProps} />

              <PastWeekSalesChart />
            </Card>
          </PerfectScrollbar>
        </Grid>
      )}
    </Draggable>
  )
}

const styles = theme => ({
  root: { flexGrow: 1 },
  homeCard: {
    height: "26rem",
    borderRadius: "15px",
    alignItems: "center",
  },
})
export default withStyles(styles)(HomePageWideChart)
