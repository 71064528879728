import React, { useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Grid,
  Hidden,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core"
import moment from "moment"
import BarChartIcon from "@material-ui/icons/BarChart"
import HomeImage from "./HomeImage"
import RevenueByBrand from "./RevenueByBrand"
import UserContext from "../../../context/UserContext"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    backgroundColor: theme.palette.common.white,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  image: {
    width: "100%",
  },
  dateSection: {
    marginBottom: "1.5rem",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  headerCard: {
    borderRadius: "15px",
    marginBottom: "1.5rem",
  },
}))

function Header({ person, className, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(UserContext)
  const expanded = state.expandedHome
  const firstName = person && person.firstName ? person.firstName : "Friend"
  const myDate = new Date()
  const hrs = myDate.getHours()
  const greet =
    hrs < 12
      ? "Good Morning"
      : hrs >= 12 && hrs <= 17
      ? "Good Afternoon"
      : hrs >= 17 && hrs <= 24
      ? "Good Evening"
      : "Hi"
  const handleExpandClick = () => {
    dispatch({ type: "TOGGLE_HOME_PAGE_HEADER" })
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.headerCard}>
        <CardHeader
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
          title={
            expanded ? (
              ""
            ) : (
              <Typography color="textPrimary">
                {greet}, {firstName}
              </Typography>
            )
          }
          subheader={
            expanded ? (
              ""
            ) : (
              <Typography variant="overline" color="textSecondary">
                {moment(myDate).format("dddd, MMMM Do, YYYY")}
              </Typography>
            )
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              alignItems="center"
              container
              justify="space-between"
              spacing={3}
            >
              <Grid item md={6} xs={12}>
                <Typography variant="overline" color="textSecondary">
                  {moment(myDate).format("dddd, MMMM Do, YYYY")}
                </Typography>
                <Typography variant="h3" color="textPrimary">
                  {greet}, {firstName}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  Here’s what’s happening at work today
                </Typography>
                <Box mt={2}>
                  <Button className={classes.action} variant="contained">
                    <BarChartIcon className={classes.actionIcon} />
                    Today's summary
                  </Button>
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <HomeImage />
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
