import React, { useState, useContext } from "react"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  Grid,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Label from "../../../../Global/Label"
import { Link } from "gatsby"
import TasksContext from "../../../../../context/TasksContext"
import moment from "moment"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { Eye as ViewIcon, Edit as EditIcon } from "react-feather"

const TaskDepartment = task => {
  if (task) {
    if (task.quoteTask && task.quoteTask.length > 0) return "quote"
    if (task.orderTask && task.orderTask.length > 0) return "order"
    if (task.itemTask && task.itemTask.length > 0) return "item"
    if (task.projectTask) return "project"
    else return "No department"
  } else return "No task"
}
const TaskDetails = ({ classes }) => {
  const { state, dispatch } = useContext(TasksContext)
  const [editTask, setEditTask] = useState(false)
  const task = state.selectedTask ? state.selectedTask : state.tasks[0]
  const userPermission = state.userPerm ? state.userPerm : ""
  const taskDepartment = task => {
    if (task) {
      if (task.quoteTask.length > 0) return "quote"
      if (task.orderTask.length > 0) return "order"
      if (task.itemTask.length > 0) return "item"
      if (task.projectTask.length > 0) return "project"
    } else return ""
  }

  return (
    <>
      <Card className={classes.detailCard}>
        <CardHeader
          title={task ? task.title : ""}
          subheader={
            task && task.assignedTo
              ? `Assigned To: ${task.assignedTo?.person.firstName} ${task.assignedTo?.person.lastName}`
              : "Unassigned"
          }
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setEditTask(true)}
            >
              <SvgIcon>
                <EditIcon />
              </SvgIcon>
            </IconButton>
          }
        />
        <CardContent>
          <Typography>
            {task && task.completed ? (
              <Label color="success">Completed</Label>
            ) : (
              ""
            )}
            Note: {task && task.note ? task.note : ""}
            <br />
            Due:{" "}
            {task && task.dueDate
              ? moment(task.dueDate).format("MMM Do, YYYY, h:mm a")
              : ""}
            <br />
          </Typography>
          <Divider />
          {task && task.projectTask ? (
            <div>
              <Typography>Project Task</Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                component={Link}
                to={`/${userPermission}/projects/${task.projectTask.project.id}`}
                state={{ id: `${task.projectTask.project.id}` }}
              >
                Project {task.projectTask.project.id}
              </Button>
            </div>
          ) : task && task.orderTask ? (
            <div>
              <Typography>Order Task</Typography>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                component={Link}
                to={`/${userPermission}/orders/${task.orderTask.order.id}`}
                state={{ id: `${task.orderTask.order.id}` }}
              >
                Order {task.orderTask.order.id}
              </Button>
            </div>
          ) : (
            "not a project task, not an order task"
          )}
        </CardContent>
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {},
  detailCard: {
    marginTop: theme.spacing(1),
  },
})
export default withStyles(styles)(TaskDetails)
