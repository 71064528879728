import React, { useState, useContext, useEffect } from "react"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  SvgIcon,
  List,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import TasksContext from "../../../../../../context/TasksContext"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import TaskCard from "../TaskCard"
import { EyeOff as HideIcon } from "react-feather"
import { source } from "vfile-message"
import StaffCardList from "./StaffCardList"

// Reorder items or lists
const reorder = (list, startIndex, endIndex) => {
  // copy the list to a new array "result"
  const result = [...list]
  // remove object from "result" array
  const [removed] = result.splice(startIndex, 1)
  //   update "position" property of removed object
  var newPosition = endIndex + 1

  const updatedRemoved = { ...removed, position: newPosition }
  //   insert "removed"object back into array in updated place
  result.splice(endIndex, 0, updatedRemoved)

  // create new array newOrder with updated "position" property in each object
  const newOrder = result.map((res, index) => {
    const newPos = index + 1
    return { ...res, position: newPos }
  })
  console.log(newOrder)
  // return the new array
  return newOrder
}
// Move items between lists
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = source
  const destClone = destination

  console.log("source clone")
  console.log(sourceClone)
  console.log("dest clone")
  console.log(destClone)
  // const sourceClone = source
  // // const sourceClone = Array.from(source)
  // const destClone = destination
  // // const destClone = Array.from(destination)
  // const [removed] = sourceClone.splice(droppableSource.index, 1)

  // destClone.splice(droppableDestination.index, 0, removed)

  // const result = {}
  // result[droppableSource.droppableId] = sourceClone
  // result[droppableDestination.droppableId] = destClone

  // return result
}

const AssignTasks = ({ classes }) => {
  const { state, dispatch } = useContext(TasksContext)
  const tasks = state.tasks
  const staffTasks = state.staffTasks
  const allStaffColumns = state.allStaffColumns
  const activeStaffColumns = state.activeStaffColumns

  const unassignedTasks = tasks.filter(tsk => tsk.assignedTo === null)
  const showUnassignedTasks = state.showUnassignedTasks
  const [selected, setSelected] = useState([])
  const [selectedList, setSelectedList] = useState("")
  const [destinationList, setDestinationList] = useState("")
  const [taskId, setTaskId] = useState(null)
  const [moveType, setMoveType] = useState("item")
  const [isUpdating, setIsUpdating] = useState(false)
  const [newPosition, setNewPosition] = useState(null)
  const [listItems, setListItems] = useState([])

  // const taskItems = tasks.map(function (el) {
  //   var o = Object.assign({}, el)
  //   o.gridType = "taskItem"
  //   return o
  // })
  const taskItems = staffTasks.map(function (el) {
    var o = Object.assign({}, el)
    o.gridType = "taskItem"
    return o
  })

  const { data, loading, error } = useQuery(STAFF_QUERY, {
    onCompleted: data => {
      const staffMembers = data.staff
      const updatedStaffMembers = staffMembers.map(function (el) {
        var o = Object.assign({}, el)
        o.gridType = "taskList"
        return o
      })
      console.log(updatedStaffMembers)
      const unassignedMember = {
        gridType: "taskList",
        id: "0",
        person: { uid: "", firstName: "Unassigned", lastName: "Tasks" },
      }
      const newArray = updatedStaffMembers.concat(unassignedMember)
      console.log(newArray)
      dispatch({ type: "SET_ALL_STAFF_COLUMNS", payload: newArray })
      dispatch({ type: "SET_ACTIVE_STAFF_COLUMNS", payload: newArray })

      setSelected(newArray)
    },
  })

  function onDragStart(result) {
    if (result.source.droppableId === "staffCards") {
      var draggingList = activeStaffColumns[result.source.index]
      setSelectedList(draggingList)
      setMoveType("list")
    }
    if (result.source.droppableId.includes("list")) {
      console.log("result")
      console.log(result)
      setMoveType("item")
      const selectedTask = taskItems[result.source.index]
      dispatch({ type: "SET_SELECTED_TASK", payload: selectedTask })

      const selectedTaskId = parseInt(selectedTask.id)
      setTaskId(selectedTaskId)
      // console.log("task item")
    }

    // if (selected[result.source.index].gridType === "taskList") {
    //   var draggingList = selected[result.source.index]
    //   setSelectedList(draggingList)
    //  console.log("task list")
    // }
    // if (selected[result.source.index].gridType === "taskItem") {
    //   var draggingTask = selected[result.source.index]
    //   // setSelectedList(draggingList)
    //   console.log("task item")
    // }
  }
  // function onDragUpdate(result) {
  //   console.log("updating")
  //   console.log(result)
  // }

  function onDragEnd(result) {
    const { source, destination } = result
    if (!result.destination) {
      return
    }

    if (
      moveType === "item" &&
      result.source.droppableId.includes("list") &&
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return
    }
    if (isUpdating) {
      return
    }
    if (
      moveType === "list" &&
      result.destination.droppableId === "staffCards"
    ) {
      const updatedLists = reorder(
        selected,
        result.source.index,
        result.destination.index
      )

      dispatch({ type: "SET_ACTIVE_STAFF_COLUMNS", payload: updatedLists })
    }
    if (
      moveType === "item" &&
      result.destination.droppableId.includes("list")
    ) {
      const sInd = source.droppableId
      const dInd = destination.droppableId
      if (sInd === dInd) {
        const updatedTasks = reorder(
          tasks,
          result.source.index,
          result.destination.index
        )
        const updatedPosition = parseInt(
          updatedTasks.find(t => t.id == taskId).position
        )
        console.log("moved an item")
        dispatch({ type: "SET_TASKS", payload: updatedTasks })
        setNewPosition(updatedPosition)
      } else {
        const getListNumber = vari => {
          var listNumber = vari.slice(10)
          // console.log(listNumber)
          return listNumber
        }
        const sourceListNumber = getListNumber(sInd)
        const destinationListNumber = getListNumber(dInd)
        // const fromList = selected.find(sel=>sel.id === )
        const sourceTasks = tasks.filter
        console.log("source tasks")
        console.log(tasks)
        console.log("source")
        console.log(source)
        console.log("destination")
        console.log(destination)
        console.log("destination droppable id")
        console.log(dInd)
        console.log("source droppable id")
        console.log(sInd)
        console.log(selected)
        const result = move(
          selected[parseInt(sInd.index)],
          selected[parseInt(dInd.index)],
          source,
          destination
        )
        // const result = move(tasks[sInd], tasks[dInd], source, destination)
        // const newTaskList = [...tasks]
        // newTaskList[sInd] = result[sInd]
        // newTaskList[dInd] = result[dInd]
        // const updatedPosition = parseInt(
        //   newTaskList.find(t => t.id == taskId).position
        // )
        // dispatch({ type: "SET_TASKS", payload: newTaskList })
        // setNewPosition(updatedPosition)
        // console.log("moved an item")
      }

      // var movedAlert = JSON.parse(JSON.stringify(selTask))
      // movedAlert = { ...movedAlert, completed: false }
      // setUndoAlert(movedAlert)
      // const updatedTasks = reorder(
      //   tasks,
      //   result.source.index,
      //   result.destination.index
      // )
      // const updatedPosition = parseInt(
      //   updatedTasks.find(t => t.id == taskId).position
      // )
      // // setTasks(updatedTasks)
      // dispatch({ type: "SET_TASKS", payload: updatedTasks })

      // setNewPosition(updatedPosition)
    }
  }

  const handleSelect = st => {
    if (activeStaffColumns.some(sel => sel.id === st.id)) {
      const newArray = activeStaffColumns.filter(s => s.id != st.id)
      // setSelected(newArray)
      dispatch({ type: "SET_ACTIVE_STAFF_COLUMNS", payload: newArray })
      // } else setSelected([...selected, st])
    } else {
      const updatedArray = [...activeStaffColumns, st]
      dispatch({ type: "SET_ACTIVE_STAFF_COLUMNS", payload: updatedArray })
    }
  }

  const handleSelectList = () => {
    // keep in case you want to do anything else when you move the list
    console.log("move list")
  }

  if (loading) return "loading..."
  if (error) return "error..."
  return (
    <>
      <List>
        {allStaffColumns &&
          allStaffColumns.map(st => {
            const name = st.person.firstName + " " + st.person.lastName
            const inSelected = activeStaffColumns.some(sel => sel.id === st.id)
            return (
              <Button
                onClick={() => handleSelect(st)}
                key={st.id}
                size="small"
                color={inSelected ? "secondary" : "default"}
                variant={inSelected ? "contained" : "outlined"}
                className={classes.nameButton}
              >
                {name}
              </Button>
            )
          })}
      </List>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        // onDragUpdate={onDragUpdate}
      >
        <Droppable droppableId="staffCards" type={`droppableList`}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={
                snapshot.isDraggingOver
                  ? clsx(classes.dragOver, classes.dragBackground)
                  : classes.dragBackground
              }
            >
              <Grid container spacing={3} direction="row">
                <StaffCardList onSelectList={handleSelectList} />
                {JSON.stringify(provided.droppableProps)}
                {JSON.stringify(snapshot)}
                {provided.placeholder}
              </Grid>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

const styles = theme => ({
  root: {},
  nameButton: {
    margin: theme.spacing(1),
  },
  staffCard: {
    width: "100%",
    minHeight: "300px",
    marginTop: theme.spacing(1),
    borderRadius: "15px",
    boxShadow: "0 2px 4px 2px rgba(0,0,0, .08)",
  },
  taskCard: {
    marginTop: theme.spacing(1),
    borderRadius: "15px",
    boxShadow: "0 2px 4px 2px rgba(0,0,0, .15)",
  },
  draggingTask: {
    backgroundColor: "#ADD8E6",
  },
  dragOver: {
    backgroundColor: "#ADD8E6",
  },
  dragBackground: {},
})
const STAFF_QUERY = gql`
  query {
    staff {
      id
      person {
        uid
        firstName
        lastName
      }
    }
  }
`
export default withStyles(styles)(AssignTasks)
