import React, { useState, useContext } from "react"
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Header from "./Header"
// import Results from "./Results"
import PerfectScrollbar from "react-perfect-scrollbar"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import SortContext from "../../../../context/SortContext"
import Page from "../../../Global/Page"
import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather"
const tabs = [
  {
    value: "invoices",
    label: "Invoices",
  },
  {
    value: "payments",
    label: "Payments",
  },
]
const sortOptions = [
  {
    value: "updatedAt|desc",
    label: "Last update (newest first)",
  },
  {
    value: "updatedAt|asc",
    label: "Last update (oldest first)",
  },
  {
    value: "orders|desc",
    label: "Total orders (high to low)",
  },
  {
    value: "orders|asc",
    label: "Total orders (low to high)",
  },
]
const SupplierBillsPaymentsView = ({ classes }) => {
  const page = 1
  const search = ""
  const pageSize = 10
  const [currentTab, setCurrentTab] = useState("invoices")
  //   const { loading, error, data } = useQuery(ITEM_BATCH_QUERY, {
  //     variables: {
  //       search: search,
  //       page: page,
  //       pageSize: pageSize,
  //     },
  //     fetchPolicy: "cache-and-network",
  //   })

  const handleTabsChange = (event, value) => {
    // const updatedFilters = {
    //   ...filters,
    //   billsDue: null,
    //   current: null,
    // }

    // if (value !== "all") {
    //   updatedFilters[value] = true
    // }

    // setFilters(updatedFilters)

    setCurrentTab(value)
  }
  const tabSwitch = currentTab => {
    switch (currentTab) {
      case "invoices":
        return "invoices"
      case "payments":
        return "payments"
      default:
        return "invoices"
    }
  }
  //   if (loading) return <Loading />
  //   if (error) return <Error />
  //   if (data == undefined) return ""
  //   if (data == null) {
  //     return ""
  //   }
  //   const res = data.itemBatches

  return (
    <Page className={classes.root} title="Supplier Categories">
      <Container maxWidth={false}>
        <Header />
        <Card className={classes.roundedCard}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          <Divider />
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            <form
              noValidate
              onSubmit={event => {
                event.preventDefault()
                // handleSearch(event)
              }}
            >
              <TextField
                className={classes.queryField}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                // onChange={event => setSearchBar(event.target.value)}
                placeholder="Search suppliers"
                // value={searchBar}
                variant="outlined"
              />

              <Button
                className={classes.searchButton}
                type="submit"
                variant="contained"
                color="primary"
              >
                Search
              </Button>
            </form>

            <Box flexGrow={1} />
            <TextField
              label="Sort By"
              name="sort"
              size="small"
              //   onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              //   value={sort}
              variant="outlined"
            >
              {sortOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box>{tabSwitch(currentTab)}</Box>
        </Card>
        {/* {JSON.stringify(data)} */}
        {/* <Results res={res} /> */}
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    height: "100%",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  queryField: {
    // width: 500,
    margin: 1,
  },
  searchButton: {
    margin: 1,
  },
  bulkOperations: {
    position: "relative",
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: "absolute",
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  table: {
    marginTop: 5,
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    fontSize: "13px",
  },
  roundedCard: {
    borderRadius: "15px",
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.link,
  },
})
export default withStyles(styles)(SupplierBillsPaymentsView)
