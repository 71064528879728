import React, { useContext } from "react"
import TasksContext from "../../../../../../context/TasksContext"
import StaffCard from "./StaffCard"

const StaffCardList = ({ onSelectList }) => {
  const { state, dispatch } = useContext(TasksContext)
  const activeStaffColumns = state.activeStaffColumns
  return activeStaffColumns.map((stf, index) => {
    return (
      <StaffCard
        key={stf.id}
        stf={stf}
        index={index}
        onSelectList={onSelectList}
      />
    )
  })
}

export default StaffCardList
