import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"

import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  icon: {
    padding: "3px",
  },
  statusButton: {
    margin: "3px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}))

function BusinessUnitList({
  className,
  businessUnits,
  sbu,
  setSbu,
  section,
  setSection,
  ...rest
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  const handleBusinessUnitChange = event => {
    event.persist()
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
    }
    setSbu(value)
  }
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={sbu ? sbu.name : "Business Units"}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardActions>
          <TextField
            fullWidth
            name="option"
            size="small"
            onChange={handleBusinessUnitChange}
            select
            SelectProps={{ native: true }}
            value={sbu}
            variant="outlined"
          >
            <option value={0}>All</option>
            {businessUnits.map(b => (
              <option key={b.id} value={b}>
                {b.name}
              </option>
            ))}
          </TextField>
        </CardActions>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => setSection("items")}
                  size="small"
                  fullWidth
                  variant={section === "items" ? "contained" : "outlined"}
                  color="secondary"
                >
                  Items
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => setSection("orders")}
                  size="small"
                  fullWidth
                  variant={section === "orders" ? "contained" : "outlined"}
                  color="secondary"
                >
                  Orders
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => setSection("customers")}
                  size="small"
                  fullWidth
                  variant={section === "customers" ? "contained" : "outlined"}
                  color="secondary"
                >
                  Customers
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => setSection("suppliers")}
                  size="small"
                  fullWidth
                  variant={section === "suppliers" ? "contained" : "outlined"}
                  color="secondary"
                >
                  Suppliers
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => setSection("campaigns")}
                  size="small"
                  fullWidth
                  variant={section === "campaigns" ? "contained" : "outlined"}
                  color="secondary"
                >
                  Campaigns
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => setSection("strategy")}
                  size="small"
                  fullWidth
                  variant={section === "strategy" ? "contained" : "outlined"}
                  color="secondary"
                >
                  Strategy
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => setSection("metrics")}
                  size="small"
                  fullWidth
                  variant={section === "metrics" ? "contained" : "outlined"}
                  color="secondary"
                >
                  Metrics
                </Button>
              </TableCell>
              <TableCell>
                {/* <Button
                size="small"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                More
              </Button> */}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </Card>
  )
}

BusinessUnitList.propTypes = {
  className: PropTypes.string,
}

export default BusinessUnitList
