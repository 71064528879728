import React, { useState } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Page from "../../../Global/Page"
import AddStaff from "./AddStaff"
import Header from "./Header"
import StaffList from "./StaffList"
import StaffScheduleQuickView from "./StaffScheduleQuickView"
import PerfectScrollbar from "react-perfect-scrollbar"

const ManageStaffDashboardView = ({ classes }) => {
  const [showAddStaff, setShowAddStaff] = useState(false)
  const { loading, error, data } = useQuery(STAFF_LIST_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      console.log(data)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const staffList = data.staff
  return (
    <Page className={classes.root} title="Manage Staff">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header
            showAddStaff={showAddStaff}
            setShowAddStaff={setShowAddStaff}
          />
          <Grid container alignItems="flex-start" spacing={3}>
            {showAddStaff && (
              <Grid item md={4} xl={3} xs={12}>
                <AddStaff
                  showAddStaff={showAddStaff}
                  setShowAddStaff={setShowAddStaff}
                />
              </Grid>
            )}
            <Grid item md={4} xl={3} xs={12}>
              <StaffList staffList={staffList} />
            </Grid>
            <Grid item md={4} xl={3} xs={12}>
              <StaffScheduleQuickView />
            </Grid>
          </Grid>
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
})
const STAFF_LIST_QUERY = gql`
  query {
    staff {
      id
      staff
      manager
      person {
        uid
        firstName
        lastName
      }
    }
  }
`
export default withStyles(styles)(ManageStaffDashboardView)
