import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { ShoppingCart as OrderIcon } from "react-feather"
import moment from "moment"
function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const HomePageInquiries = ({ classes, index }) => {
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const [inquiryList, setInquiryList] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(7)
  const { data, loading, error } = useQuery(RECENT_INQUIRIES_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: "",
    },
    onCompleted: data => {
      const inquiryData = data.inquiries.objects
      setInquiryList(inquiryData)
    },
  })

  return (
    <Draggable draggableId="inquiries" index={index}>
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          sm={8}
          index={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          //   {...provided.dragHandleProps}
        >
          <Card className={classes.homeCard}>
            <CardHeader
              title="Recent Inquiries"
              {...provided.dragHandleProps}
              action={
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={
                    <SvgIcon>
                      <OrderIcon />
                    </SvgIcon>
                  }
                  component={Link}
                  to={`/${userPermission}/inquiries`}
                >
                  Inquiries
                </Button>
              }
            />
            {/* <CardContent>{JSON.stringify(orderList)}</CardContent> */}

            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
              //   <Typography>{JSON.stringify(orderList)}</Typography>
              <PerfectScrollbar>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>SBU</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell align="right">Processed</TableCell>
                        {/* <TableCell></TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inquiryList
                        ? inquiryList.map(i => {
                            const customerName = i.inquiryPerson ? (
                              <Typography>
                                {i.inquiryPerson.firstName}{" "}
                                {i.inquiryPerson.lastName}
                              </Typography>
                            ) : (
                              ""
                            )

                            return (
                              <TableRow key={i.id}>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    component={Link}
                                    to={`/${userPermission}/inquiries/${i.id}`}
                                  >
                                    {i.id}
                                  </Button>
                                </TableCell>
                                <TableCell>{i.businessUnit.name}</TableCell>
                                <TableCell>{customerName}</TableCell>
                                <TableCell align="right">
                                  {i.new
                                    ? "new"
                                    : i.processedAt
                                    ? moment(i.processedAt).format(
                                        "YYYY/MM/DD | hh:mm"
                                      )
                                    : ""}
                                </TableCell>
                                {/* <TableCell></TableCell> */}
                              </TableRow>
                            )
                          })
                        : ""}
                    </TableBody>
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
            )}
          </Card>
        </Grid>
      )}
    </Draggable>
  )
}

const styles = theme => ({
  root: {},
  homeCard: {
    height: "26rem",
    borderRadius: "15px",
  },
  container: {
    width: "100%",
    margin: "3rem",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.link,
  },
})
const RECENT_INQUIRIES_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String) {
    inquiries(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        inquiryPerson {
          id
          firstName
          lastName
          isPerson
          orgName
        }
        source
        businessUnit {
          id
          name
        }
        createdAt
        processedAt
        processedBy {
          id
          person {
            firstName
            lastName
          }
        }
      }
    }
  }
`
export default withStyles(styles)(HomePageInquiries)
