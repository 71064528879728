import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import clsx from "clsx"
import {
  ArrowLeft as BackArrowIcon,
  ArrowRight as ForwardArrowIcon,
  Calendar as CalendarIcon,
  EyeOff as HideIcon,
} from "react-feather"
import Alert from "@material-ui/lab/Alert"

const AddStaff = ({ showAddStaff, setShowAddStaff, classes, ...rest }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState("")
  const [handleAddStaff, { data, loading }] = useMutation(ADD_STAFF_MUTATION, {
    variables: {
      firstName: firstName,
      lastName: lastName,
      email: email,
    },
    onCompleted: data => {
      console.log(data)
      setMessage(data.createStaffPrereg.message)
      setFirstName("")
      setLastName("")
      setEmail("")
      setIsSubmitting(false)
    },
  })
  const handleForm = () => {
    setIsSubmitting(true)
    handleAddStaff()
  }
  return (
    <Card className={clsx(classes.root, classes.roundedCard)} {...rest}>
      <CardHeader
        title="Add Staff"
        action={
          <IconButton onClick={() => setShowAddStaff(false)}>
            <SvgIcon color="primary" fontSize="small">
              <HideIcon />
            </SvgIcon>
          </IconButton>
        }
      />
      <Divider />{" "}
      <form
        onSubmit={event => {
          event.preventDefault()
          handleForm(event)
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell align="right">
                <TextField
                  fullWidth
                  // helperText={touched.firstName && errors.firstName}
                  label="First Name"
                  margin="dense"
                  size="small"
                  // onBlur={handleBlur}
                  onChange={event => setFirstName(event.target.value)}
                  type="text"
                  value={firstName}
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell align="right">
                <TextField
                  fullWidth
                  // helperText={touched.firstName && errors.firstName}
                  label="Last Name"
                  margin="dense"
                  size="small"
                  // onBlur={handleBlur}
                  onChange={event => setLastName(event.target.value)}
                  type="text"
                  value={lastName}
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">
                <TextField
                  fullWidth
                  // helperText={touched.firstName && errors.firstName}
                  label="Email"
                  margin="dense"
                  size="small"
                  // onBlur={handleBlur}
                  onChange={event => setEmail(event.target.value)}
                  type="email"
                  value={email}
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email Invite</TableCell>
              <TableCell align="right">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  {isSubmitting ? "Sending..." : "Send"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </form>
      {message && (
        <Alert
          onClose={() => {
            setMessage("")
          }}
        >
          {message}
        </Alert>
      )}
    </Card>
  )
}

const ADD_STAFF_MUTATION = gql`
  mutation ($email: String, $firstName: String, $lastName: String) {
    createStaffPrereg(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      person {
        uid
        firstName
        lastName
      }
      staffPersonStatus {
        id
        active
        setup
        activatedDate
        setupDate
      }
      message
    }
  }
`
const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  roundedCard: {
    borderRadius: "15px",
    width: "100%",
  },
})
export default withStyles(styles)(AddStaff)
