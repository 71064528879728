import React, { useState } from "react"
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import { Link } from "gatsby"
import gql from "graphql-tag"
import clsx from "clsx"
const StaffList = ({ staffList, classes, ...rest }) => {
  return (
    <Card className={clsx(classes.root, classes.roundedCard)} {...rest}>
      <CardHeader title="Staff List" />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Groups</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {staffList.map(staff => {
            const name = staff.person.firstName + " " + staff.person.lastName
            const uid = staff.person.uid
            return (
              <TableRow key={staff.id}>
                <TableCell>
                  <Link
                    className={classes.link}
                    to={`./${uid}`}
                    state={{ uid: { uid } }}
                  >
                    {name}
                  </Link>
                </TableCell>
                <TableCell align="right">My Groups</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Card>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  roundedCard: {
    borderRadius: "15px",
    width: "100%",
  },
  link: {
    color: theme.palette.text.link,
    textDecoration: "none",
  },
})
export default withStyles(styles)(StaffList)
