import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { List as ListIcon } from "react-feather"
import moment from "moment"

const HomePageTasks = ({ classes, index }) => {
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const [taskList, setTaskList] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const { data, loading, error } = useQuery(MY_TASKS_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: "",
      staffId: 1,
      completedFilter: false,
    },
    onCompleted: data => {
      const taskData = data.taskManager.objects
      setTaskList(taskData)
    },
  })
  if (loading) return "loading..."
  return (
    <Draggable draggableId="tasks" index={index}>
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          sm={4}
          index={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card className={classes.homeCard}>
            <CardHeader
              title="My Tasks"
              {...provided.dragHandleProps}
              action={
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={
                    <SvgIcon>
                      <ListIcon />
                    </SvgIcon>
                  }
                  component={Link}
                  to={`/${userPermission}/tasks`}
                >
                  Tasks
                </Button>
              }
            />
            {/* <CardContent>{JSON.stringify(orderList)}</CardContent> */}

            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>

                    <TableCell align="right">Priority</TableCell>
                    {/* <TableCell></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taskList
                    ? taskList.map(t => {
                        return (
                          <TableRow key={t.id}>
                            <TableCell>
                              <Typography>{t.title}</Typography>
                              <Typography>
                                Due:{" "}
                                {moment(t.dueDate).format("YYYY/MM/DD | hh:mm")}
                              </Typography>
                            </TableCell>

                            <TableCell align="right">{t.priority}</TableCell>
                          </TableRow>
                        )
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      )}
    </Draggable>
  )
}

const styles = theme => ({
  root: {},
  homeCard: {
    height: "26rem",
    borderRadius: "15px",
  },
  container: {
    width: "100%",
    margin: "3rem",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.link,
  },
})
const MY_TASKS_QUERY = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $search: String
    $staffId: Int
    $completedFilter: Boolean
  ) {
    taskManager(
      page: $page
      pageSize: $pageSize
      search: $search
      staffId: $staffId
      completedFilter: $completedFilter
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        title
        dueDate
        priority
        position
      }
    }
  }
`
export default withStyles(styles)(HomePageTasks)
