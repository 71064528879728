import React, { useState, useContext } from "react"
import { Container, Grid, makeStyles } from "@material-ui/core"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../Utils/Loading"
import Error from "../../Utils/Error"
import Page from "../../Global/Page"
import Header from "./Header"
import HomePageChart from "./HomePageCards/HomePageChart"
import HomePageWideChart from "./HomePageCards/HomePageWideChart"
import HomePageTasks from "./HomePageCards/HomePageTasks"
import HomePageSettings from "./HomePageCards/HomePageSettings"
import HomePageSupplierBatches from "./HomePageCards/HomePageSupplierBatches"
import HomePageProjects from "./HomePageCards/HomePageProjects"
import HomePageOrders from "./HomePageCards/HomePageOrders"
import HomePageInquiries from "./HomePageCards/HomePageInquiries"
import HomePageMessages from "./HomePageCards/HomePageMessages"
import UserContext from "../../../context/UserContext"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const ManagerHome = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(UserContext)
  const [person, setPerson] = useState({})
  const [selectedCard, setSelectedCard] = useState("")
  const [isUpdating, setIsUpdating] = useState(false)
  const homePageCards = state.homePageCards
  const homePageHeader = state.homePageHeader
  const { data, loading, error } = useQuery(MY_HOMEPAGE_QUERY, {
    onCompleted: data => {
      const personData = data.me.person
      setPerson(personData)
      // const settingsData = JSON.parse(data.me.settings)
      // if (settingsData && settingsData.length > 0) {
      //   dispatch({ type: "SET_HOME_PAGE_CARDS", payload: settingsData })
      // }
    },
  })
  function getHomeCard(card, index) {
    switch (card) {
      case "settings":
        return <HomePageSettings key={index} index={index} />
      case "tasks":
        return <HomePageTasks key={index} index={index} />
      case "messages":
        return <HomePageMessages key={index} index={index} />
      case "charts":
        return <HomePageChart key={index} index={index} />
      case "wideCharts":
        return <HomePageWideChart key={index} index={index} />
      case "inquiries":
        return <HomePageInquiries key={index} index={index} />
      case "orders":
        return <HomePageOrders key={index} index={index} />
      case "batches":
        return <HomePageSupplierBatches key={index} index={index} />
      case "projects":
        return <HomePageProjects key={index} index={index} />
      default:
        return ""
    }
  }

  function onDragStart(result) {
    const draggingCard = homePageCards[result.source.index]
    setSelectedCard(draggingCard)
  }
  function onDragEnd(result) {
    if (!result.destination) {
      return
    }
    if (
      result.destination.index === result.source.index &&
      result.destination.droppableId === result.source.droppableId
    ) {
      return
    }
    if (isUpdating) {
      return
    }
    if (result.destination.droppableId === "cards") {
      console.log(homePageCards)
      console.log(result.source.index)
      console.log(result.destination.index)
      const updatedCardList = reorder(
        homePageCards,
        result.source.index,
        result.destination.index
      )
      dispatch({ type: "SET_HOME_PAGE_CARDS", payload: updatedCardList })
    }
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header person={person} />
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="cards">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? "#d3d3d3" : "",
                }}
                {...provided.droppableProps}
              >
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="stretch"
                >
                  {homePageCards &&
                    homePageCards.map((card, index) =>
                      getHomeCard(card, index)
                    )}
                  {provided.placeholder}
                </Grid>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </Page>
  )
}

const MY_HOMEPAGE_QUERY = gql`
  query {
    me {
      id
      manager
      staff
      person {
        firstName
        lastName
        uid
        profileImageUrl
      }
    }
  }
`

export default ManagerHome
