import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { PlusCircle as AddIcon } from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { useLocation } from "@reach/router"

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    color: "#000000",
    textDecoration: "none",
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            className={classes.link}
            to={`/${userPermission}`}
            component={Link}
          >
            Dashboard
          </Link>
          <Link
            variant="body1"
            color="inherit"
            className={classes.link}
            to={`/${userPermission}/supply/suppliers`}
            component={Link}
          >
            Suppliers
          </Link>
          <Typography variant="body1" color="textPrimary">
            Bills and Payments
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Bils and Payments
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained">
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <AddIcon />
          </SvgIcon>
          New Bill
        </Button>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  batch: PropTypes.object.isRequired,
}

export default Header
