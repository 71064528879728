import React, { useState } from "react"
import clsx from "clsx"
import {
  Box,
  Card,
  CardContent,
  Tab,
  Tabs,
  Divider,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core"
import Categories from "./Categories"
const tabs = [
  {
    value: "categories",
    label: "Categories",
  },
  {
    value: "stock",
    label: "Stock",
  },
]
const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const Items = ({ className, ...rest }) => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState("categories")
  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }
  const pageSwitch = currentTab => {
    switch (currentTab) {
      case "categories":
        return <Categories />
      case "stock":
        return "stock"

      default:
        return "Default"
    }
  }
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map(tab => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        <Divider />
        <CardContent>{pageSwitch(currentTab)}</CardContent>
      </Card>
    </>
  )
}

export default Items
