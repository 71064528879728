import React, { Component } from "react"
import { navigate } from "gatsby"

import Loading from "../Utils/Loading"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"

const ManagerRoute = ({ component: Component, location, ...rest }) => {
  const { loading, error, data } = useQuery(IS_ADMIN_QUERY)
  if (loading) return <Loading />
  if (error) return navigate("/")
  if (data === undefined) return navigate("/")
  const isAuth = data.me.manager ? true : false
  if (!isAuth) {
    return navigate("/")
  }
  return <Component {...rest} isAuth={isAuth} />
}
export default ManagerRoute

const IS_ADMIN_QUERY = gql`
  query {
    me {
      manager
      staff
    }
  }
`
